<template>
  <div class="main">
    <!--导航栏--->
    <section class="top">
      <van-nav-bar title="订单详情" safe-area-inset-top z-index="1000" left-arrow :fixed="isTop" @click-left="onClickLeft" />
    </section>
    <!-- <span>红尘坊</span> -->
    <!-- <span>{{ shijian }}</span> -->
    <!-- 订单状态 -->
    <section class="willPay">
      <div style="width: 1rem">
        <img src="../../assets/img/willPay04.png" alt="" />
      </div>
      <div v-if="isPay === '0'">
        <div>待付款</div>
        <div class="fontColor">
          剩{{ closeTime.hours }}小时{{ closeTime.minutes }}分{{
            closeTime.second
          }}秒 自动关闭
        </div>
      </div>
      <div v-else>
        <div>
          {{
            userInfo.status === "1"
            ? "待发货"
            : userInfo.status === "2"
              ? "待收货"
              : userInfo.status === "3"
                ? "已完成"
                : userInfo.status === "5"
                  ? "已取消"
                  : "暂无状态"
          }}
        </div>
        <div class="fontColor">
          <span v-show="userInfo.status === '1'">商家正在备货中</span>
        </div>
      </div>
    </section>
    <!-- 买家信息 -->
    <section class="userInfo">
      <div style="width: 1.0811rem">
        <img src="../../assets/img/willPay03.png" alt="加载失败" />
      </div>
      <div class="userInfo-one">
        <div class="userInfo-one-info">
          <div>
            <span class="userText">{{ userInfo.userName }}</span>
            <span class="userText">{{ userInfo.telNum }}</span>
          </div>
          <div>
            <button v-if="isPay != '0' && userInfo.status == '1' && userInfo.modificationAddress == '1'"
              @click="editAddress"><span style="padding: 0.13rem 0.62rem; color: #333333;">修改</span></button>
          </div>
        </div>
        <div class="fontColor">
          <span>{{ userInfo.address }}</span>
        </div>
      </div>
    </section>
    <!-- 单个商品信息 -->
    <!-- 未付款的 -->
    <section class="shopInfo" v-if="isPay === '0'">
      <div v-for="(item, index) in willPayShopsGoods" :key="index">
        <div class="shopName">
          <van-image round width="0.6rem" :src="item[0].imgUrl" />
          <span>{{ item[0].shopName }}</span>
          <!-- 点击跳转到店铺页面-->
          <van-icon name="arrow" size="0.4rem" @click="toShopDetail(item[0].shopId)" />
        </div>
        <div class="goodsInfo" v-for="(v, i) in item" :key="i">
          <div class="goodsList">
            <van-image width="4.8rem" fit="fill" :src="v.picUrl" />
            <div class="goodsText">
              <div>
                <p>{{ v.spuName }}</p>
                <div class="size fontColor">
                  <span>规格：{{ v.specInfo }}</span>
                  <span>x{{ v.quantity }}</span>
                </div>
              </div>
              <div class="price">{{ v.salesPrice ? '￥' + v.salesPrice : '' }} {{ v.needRice && v.salesPrice ? '+' : ''
              }}{{ v.needRice ?
  v.needRice + '驿马' : '' }}</div>
            </div>
          </div>
          <div class="otherText">
            <!-- <div class="integrate">																																																																															</div> -->
            <div class="freight">
              <span>运费</span>
              <span v-if="v.freightPrice != 0 || '' || null">￥{{ v.freightPrice }}</span>
              <span v-else>0</span>
            </div>
            <div class="remark">
              <span>留言</span>
              <span class="massage fontColor toLongHide">{{
                v.userMessage || "无"
              }}</span>
            </div>
          </div>
        </div>
        <!-- 优惠券 -->
        <div v-if="goodsCoupon && goodsCoupon.length > 0">
          <div v-for="(val, index) in goodsCoupon" :key="index">
            <div class="discounts" v-show="val.shopId == item[0].shopId">
              <span>{{ val.type }} <span style="color: #999999;">{{
                val.name
              }}</span></span>
              <span>-￥{{ val.money ? Number(val.money).toFixed(2) : '0.00' }}</span>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 已付款的 -->
    <section class="shopInfo" v-if="isPay != '0'">
      <div>
        <div class="shopName" v-if="shopsGoods[0][0]">
          <van-image round width="0.6rem" :src="shopsGoods[0][0].imgUrl" />
          <span>{{ shopsGoods[0][0].shopName || "加载失败" }}</span>
          <!-- 点击跳转到店铺页面-->
          <van-icon name="arrow" size="0.4rem" @click="toShopDetail(shopsGoods[0][0].shopId)" />
        </div>
        <div class="goodsInfo" v-for="(v, i) in shopsGoods" :key="i">
          <div class="goodsList">
            <van-image width="4.8rem" :src="v[0].picUrl" />
            <div class="goodsText">
              <div>
                <p>{{ v[0].spuName }}</p>
                <div class="size fontColor">
                  <span>规格：{{ v[0].specInfo || "..." }}</span>
                  <span>x{{ v[0].quantity }}</span>
                </div>
              </div>
              <div class="price">{{ v[0].salesPrice ? '￥' + v[0].salesPrice : '' }}{{ v[0].salesPrice && v[0].needRice ?
                '+' : '' }}{{
    v[0].needRice ? v[0].needRice + '驿马' : '' }}</div>
            </div>
          </div>
          <!-- 待发货 -->
          <div class="returnGoods" v-if="userInfo.status === '1'">
            <button :class="statusArr1.includes(changeIsShow[i]) ? '' : 'buy'" @click="hopeRefund(v[0])"
              :disabled="statusArr1.includes(changeIsShow[i])">
              {{
                changeIsShow[i] === "1"
                ? "退款中"
                : changeIsShow[i] === "2"
                  ? "退货退款中"
                  : changeIsShow[i] === "3"
                    ? "已退款"
                    : changeIsShow[i] === "4"
                      ? "已退货"
                      : changeIsShow[i] === "5"
                        ? "换货中"
                        : changeIsShow[i] === "6"
                          ? "已换货"
                          : changeIsShow[i] === "7"
                            ? "已拒绝"
                            : changeIsShow[i] === "8"
                              ? "已取消"
                              : "申请退款"
              }}
            </button>
          </div>
          <!-- 待收货 -->
          <div class="returnGoods" v-else-if="userInfo.status === '2'">
            <button :class="statusArr2.includes(changeIsShow[i]) ? '' : 'buy'" @click="changeNewGoods(v[0])"
              :disabled="statusArr2.includes(changeIsShow[i])">
              {{
                changeIsShow[i] === "1"
                ? "退款中"
                : changeIsShow[i] === "2"
                  ? "退货退款中"
                  : changeIsShow[i] === "3"
                    ? "已退款"
                    : changeIsShow[i] === "4"
                      ? "已退货"
                      : changeIsShow[i] === "5"
                        ? "换货中"
                        : changeIsShow[i] === "6"
                          ? "已换货"
                          : changeIsShow[i] === "8"
                            ? "已取消"
                            : changeIsShow[i] === "9"
                              ? "仅退款申请中"
                              : changeIsShow[i] === "10"
                                ? "仅退款已拒绝"
                                : changeIsShow[i] === "11"
                                  ? "仅退款已完成"
                                  : changeIsShow[i] === "42"
                                    ? "退货退款已拒绝"
                                    : changeIsShow[i] === "31"
                                      ? "退款已取消"
                                      : changeIsShow[i] === "41"
                                        ? "退货退款已取消"
                                        : "申请售后"
              }}
            </button>
          </div>
          <!-- 已完成 -->
          <div class="returnGoods"
            v-else-if="userInfo.status === '3' && changeIsShow[i] != '8' && userInfo.logisticsShow != 1">
            <button :class="statusArr3.includes(changeIsShow[i]) ? '' : 'buy'" @click="hopeChange(v ? v[0] : '')"
              :disabled="statusArr3.includes(changeIsShow[i])">
              {{
                changeIsShow[i] === "1"
                ? "退款中"
                : changeIsShow[i] === "2"
                  ? "退货退款中"
                  : changeIsShow[i] === "3"
                    ? "已退款"
                    : changeIsShow[i] === "4"
                      ? "已退货"
                      : changeIsShow[i] === "5"
                        ? "换货中"
                        : changeIsShow[i] === "6"
                          ? "已换货"
                          : changeIsShow[i] === "7"
                            ? "换货已拒绝"
                            : changeIsShow[i] === "8"
                              ? "已取消"
                              : changeIsShow[i] === "9"
                                ? "仅退款申请中"
                                : changeIsShow[i] === "10"
                                  ? " 仅退款已拒绝"
                                  : changeIsShow[i] === "11"
                                    ? "仅退款已完成"
                                    : changeIsShow[i] === "42"
                                      ? " 退货退款已拒绝"
                                      : changeIsShow[i] === "31"
                                        ? "退款已取消"
                                        : "申请售后"
              }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- 商品总的信息 -->
    <section class="totalInfo">
      <!--  <div class="total">
        <span>商品总金额</span>
        <span>￥{{
          isPay == '1' ? userInfo.totalPrices : userInfo.orderSum
        }}</span>
      </div> -->
      <!-- 优惠券 -->
      <div v-if="userInfo.conpouDTO && userInfo.conpouDTO.length > 0">
        <div class="total" v-for="(item, index) in userInfo.conpouDTO" :key="index">
          <span>{{ item.type }} <span style="color: #999999;">{{
            item.name
          }}</span></span>
          <span>-￥{{ item.money ? Number(item.money).toFixed(2) : '0.00' }}</span>
        </div>
      </div>

      <div class="total">
        <span>运费总金额</span>
        <span v-show="userInfo.paymentType != 7">{{
          isPay != "0"
          ? userInfo.freightPrice == 0
            ? 0
            : `￥${userInfo.freightPrice}`
          : userInfo.freightSum == 0
            ? 0
            : `￥${userInfo.freightSum}`
        }}</span>
        <!-- <span v-show="userInfo.paymentType == 7">{{
          isPay != "0"
          ? userInfo.freightPrice == 0
            ? 0
            : `${userInfo.freightPrice}金叶子`
          : userInfo.freightSum == 0
            ? 0
            : `${userInfo.freightSum}金叶子`
        }}</span> -->
      </div>
      <div class="total">
        <span>{{ isPay === "0" ? "应付现金" : "实付现金" }}</span>
        <span><span v-show="userInfo.paymentType != 7">￥</span>
          <!-- {{ userInfo.paymentPrice }} -->
          {{ isPay === "0" ? userInfo.sum : userInfo.paymentPrice }}
          <!-- <span style="font-size: 12px" v-show="userInfo.paymentType == 7">
            金叶子</span> -->
        </span>
      </div>
      <div class="total" v-show="userInfo.needRice">
        <span>{{ isPay === "0" ? "应付驿马" : "实付驿马" }}</span>
        <span><span v-show="userInfo.paymentType != 7"></span>{{ userInfo.needRice }}匹
        </span>
      </div>
    </section>
    <!-- 订单编号等信息 -->
    <section class="orderInfo">
      <div class="orderNum">
        <span>订单编号</span>
        <p class="orderNum">
          {{ userInfo.id
          }}<span class="copy clipboard" :dataClipboard="userInfo.id" @click="getCopy('.clipboard')">复制</span>
        </p>
      </div>
      <div class="orderNum">
        <span>创建时间</span>
        <span>{{ userInfo.createTime }}</span>
      </div>
      <div class="orderNum" v-if="isPay === '1'">
        <span>备注</span>
        <span class="massage fontColor">{{ userInfo.userMessage }}</span>
      </div>
    </section>
    <!-- 客服 -->
    <section class="serve info-btn">
      <div @click="contactShop" class="serveBtn">
        <img style="width: 0.5rem" class="btnImg" src="../../assets/img/willPay01.png" alt="图片加载失败" /><a
          class="btnA">商家客服</a>
      </div>
      <div class="serveBtn" @click="callPhone">
        <img class="btnImg" style="width: 0.5rem" src="../../assets/img/willPay02.png" alt="图片加载失败" />
        <a class="btnA" ref="tels">官方客服</a>
      </div>
    </section>
    <!-- 到底提醒 -->
    <section class="footer">
      <!-- <p>——已经到底啦——</p> -->
    </section>
    <!-- 支付或取消订单 -->
    <section class="payment" v-if="isPay === '0'">
      <van-tabbar :fixed="isTop">
        <button @click="cancelOrder">取消订单</button>
        <button class="buy" @click="payNow"><span>立即付款</span></button>
      </van-tabbar>
    </section>
    <!-- 修改地址 -->
    <section class="payment" v-else-if="isPay === '1' && userInfo.status === '1' && userInfo.modificationAddress == '1'">
      <van-tabbar :fixed="isTop">
        <button @click="editAddress"><span>修改地址</span></button>
      </van-tabbar>
    </section>
    <!-- 确认收货按钮 -->
    <section class="payment" v-else-if="isPay === '1' && userInfo.status === '2'">
      <van-tabbar :fixed="isTop">
        <button @click="searchLogistics"><span>物流查询</span></button>
        <button class="buy" @click="confirmGet"><span>确认收货</span></button>
      </van-tabbar>
    </section>
    <!-- 物流查询/评价按钮 -->
    <section class="payment" v-else-if="isPay === '1' && userInfo.status === '3'">
      <van-tabbar :fixed="isTop">
        <button @click="searchLogistics"><span>物流查询</span></button>
        <button :class="appraisesStatus === '0' ? 'evaluate' : ''" @click="goEvaluate"
          :disabled="appraisesStatus === '0' ? false : true">
          <span>{{ appraisesStatus === "0" ? "去评价" : "已评价" }}</span>
        </button>
      </van-tabbar>
    </section>
    <!-- 取消订单/申请退款/物流查询弹出框 -->
    <section class="cancelOrderDetail">
      <!-- 取消订单 -->
      <van-action-sheet v-model="cancelOrderShow" title="订单取消" safe-area-inset-bottom :closeable="isShowClose"
        v-if="isPay === '0'">
        <!-- <p>取消后无法恢复优惠券可退回</p> -->
        <p>取消后无法恢复</p>
        <van-radio-group v-model="radio">
          <van-radio name="价格有点贵" checked-color="#E54320" icon-size="0.5rem">价格有点贵
          </van-radio>
          <van-radio name="规格/款式/数量拍错" checked-color="#E54320" icon-size="0.5rem">规格/款式/数量拍错
          </van-radio>
          <van-radio name="收货地址拍错" checked-color="#E54320" icon-size="0.5rem">收货地址拍错
          </van-radio>
          <van-radio name="暂时不需要了" checked-color="#E54320" icon-size="0.5rem">暂时不需要了
          </van-radio>
          <van-radio name="其他" checked-color="#E54320" icon-size="0.5rem">其他
          </van-radio>
        </van-radio-group>
        <div class="button">
          <button @click="closePage">暂不取消</button>
          <button class="buy" @click="closeOrder(radio)">
            <span>确定取消</span>
          </button>
        </div>
      </van-action-sheet>
      <!-- 申请退款 -->
      <van-action-sheet v-model="returnMoneyShow" title="退款原因" safe-area-inset-bottom :closeable="isShowClose"
        v-else-if="userInfo.status === '1'" cancel-text="提交" close-on-click-action @cancel="submitRefound">
        <!-- <p>取消后无法恢复优惠券可退回</p> -->
        <van-radio-group v-model="refoundInfo.refundReson">
          <van-radio name="商家未按约定时间发货" checked-color="#E54320" icon-size="0.5rem">商家未按约定时间发货
          </van-radio>
          <van-radio name="与商家协商一致退款" checked-color="#E54320" icon-size="0.5rem">与商家协商一致退款
          </van-radio>
          <van-radio name="不喜欢/不想要了" checked-color="#E54320" icon-size="0.5rem">不喜欢/不想要了
          </van-radio>
          <van-radio name="收货地址拍错" checked-color="#E54320" icon-size="0.5rem">收货地址拍错
          </van-radio>
          <van-radio name="其他" checked-color="#E54320" icon-size="0.5rem">其他
          </van-radio>
        </van-radio-group>
      </van-action-sheet>
      
    

      <!-- 物流查询 -->
      <van-action-sheet v-model="isShowLogistics" title="物流详情" safe-area-inset-bottom :closeable="isShowClose" v-else>
        <div class="logisticsTop">
          <div class="left">
            <img src="@/assets/img/logistics02.png" alt="图片加载失败" />
            <span>{{ logisticsName }}: {{ expressInfo.nu || '暂无物流' }}</span>
          </div>
          <div class="right">
            <span class="leftSpan copy" :dataClipboard="expressInfo.nu" @click="getCopy('.leftSpan')">复制</span>
            <!-- <span>官方电话</span> -->
          </div>
        </div>
        <van-steps direction="vertical" :active="0" active-color="#E54320">
          <van-step class="logisticsText" v-for="(v, i) in expressInfo.data" :key="i">
            <h5>{{ v.context }}</h5>
            <span>{{ v.ftime }}</span>
          </van-step>
        </van-steps>
      </van-action-sheet>

      <!-- 1688 -->
    <van-action-sheet 
      v-model="show1688" 
      title="退款原因" 
      safe-area-inset-bottom 
      :closeable="isShowClose"
      cancel-text="提交" 
      close-on-click-action 
      @cancel="submitRefound1688">
      <van-radio-group v-model="refoundInfo1688.refundReasonId" @change=change1688>
        <van-radio 
          v-for="val in actions3" 
          :key="val.id" 
          :name="val.id" 
          checked-color="#E54320"
          icon-size="0.5rem">{{ val.name }}</van-radio>
      </van-radio-group>
    </van-action-sheet>
      <!-- 立即付款 -->
      <!-- <ZhiFu :show="showPay" :price="userInfo.sum" :data="ids" @getSend="getShow" @notPay="notPay" @redMiPay="redMiPay"
        :payInfo="payInfo">
      </ZhiFu> -->
      <ZhiFu :show="showPay" :userInfo="userInfo" :data="ids" @getSend="getShow" @notPay="notPay" @redMiPay="redMiPay">
      </ZhiFu>
      <PaySuccess :paySuccessBoolean="isPaySuccess" @closePay="isPaySuccess = false" />
    </section>
    <!-- 弹出换货模态框 -->
    <!-- <section>
      <van-dialog v-model="isShowWin" title="请确认收货,再申请换货" show-cancel-button confirmButtonColor="#E54320"
        theme="round-button" @confirm="asConfirmGet">
      </van-dialog>
    </section> -->
  </div>
</template>

<script>
import { contact } from "@/common";
import ClipboardJS from "clipboard";
import ZhiFu from "@/views/orderIdDetail/ZhiFu.vue";
import PaySuccess from "@/views/orderIdDetail/PaySuccess.vue";
import { get1688reason } from '@/api/goodsService/index.js'

import {
  getOrderIdDetail,
  sendCancelOrder,
  applyForRefund,
  confirmGetGoods,
  getOrderLogistics,
  // getPayStatus,
} from "@/api/orderIdDetail/index.js";
import { Toast } from "vant";
export default {
  components: {
    ZhiFu,
    PaySuccess,
  },
  data() {
    return {
      statusArr1: ["1", "2", "3", "4", "5", "6", "7", "8"], //待发货
      statusArr2: ["1", "2", "3", "4", "5", "6", "8", "9", "10", "11", "41", "42", "31"], //待收货
      statusArr3: ["1", "2", "3", "4", "5", "6", "8", "9", "10", "11", "42", "31"], //已完成
      goodsCoupon: [],//优惠券
      isTop: true, //导航栏置顶,支付栏常驻
      payInfo: {}, //支付信息，包含可消费金叶子，是否开启金叶子支付等。
      isDisabled: true,//点击退款防抖
      isPay: "", //订单是否支付
      isPaySuccess: false, //订单是否支付成功
      changeIsShow: [], //申请退货
      closeTime: {
        hours: "",
        minutes: "",
        second: "",
      }, //付款倒计时
      userInfo: {}, //总信息
      returnMoneyShow: false, //申请退款面板
      cancelOrderShow: false,//取消订单面板
      isShowLogistics: false,//物流弹窗
      isShowClose: false, //是否显示关闭按钮
      // isShowWin: false, //换货模态框
      showPay: false, //展示支付弹窗
      radio: "价格有点贵", //取消订单选项
      willPayShopsGoods: [], //未付款商品信息列表
      shopsGoods: [], //商品信息列表
      payWay: "", //支付方式
      refoundInfo: {
        orderId: "",
        refundReson: "商家未按约定时间发货",
        itemId: "",
      }, //退款订单信息
      expressInfo: {}, //物流信息
      ids: [], //支付ids
      appraisesStatus: "", //是否已评价
      logisticsName: "", //物流公司名称
      timer: null, //清除定时器
      timer2: null, //清除定时器
      timer3: null, //清除定时器
      timer4: null, //清除定时器
      timer5: null, //清除定时器
      // ceshi: null, //测试用
      // shijian: ''//测试ios时间
      tradeId: '',
      actions3: [],
      show1688: false,
      dou: true, // 防抖
      refoundInfo1688: {
        orderId: "",
        refundReson: "",
        itemId: "",
        refundReasonId: ''
      }, //退款订单信息
    };
  },
  watch: {
    isPaySuccess(Boolean) {
      if (!Boolean) {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          // Toast('返回首页')
          window.webkit.messageHandlers.returnHomePage.postMessage(
            "returnHomePage"
          );
        }
        if (isAndroid) {
          window.android.returnHomePage();
        }
      }
    },
  },
  beforeMount() {
    this.getPage()
    if (this.$route.query.logistic) {
      this.searchLogistics();
    }
  },
  mounted() {
    let screenWidth = document.documentElement.getBoundingClientRect().width;
    document.documentElement.style.fontSize = screenWidth / 10 + "px";
    this.$store.commit("setSession", this.$route.query.session);
    window.paySuccess = this.paySuccess;
  },
  activated() {
    this.getPage()
    if (this.$route.query.logistic) {
      this.searchLogistics();
    }
    //查询支付方式：是否开启现金支付
    /* getPayStatus().then((res) => {
      this.payInfo.shopJoinUser = res.data.data.shopJoinUser;
      this.payInfo.welfare = res.data.data.welfare;
      // this.ceshi = res.data.data;
      // console.log(res.data.data, "???");
    }); */
  },
  methods: {
    //支付成功弹广告
    showInterstitialAD() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.showInterstitialAD.postMessage("showInterstitialAD")
      }
      if (isAndroid) {
        window.android.showInterstitialAD()
      }
    },
    //获取页面信息
    getPage() {
      // this.ceshi = this.$route.query.id;
      this.isPay = this.$route.query.types ? this.$route.query.types : "1";
      getOrderIdDetail({ id: this.$route.query.id, types: this.$route.query.types ? this.$route.query.types : "1" })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data.getOrderInfo) {
              if (res.data.data.getOrderInfo.tradeId) {
                this.get1688reason(res.data.data.getOrderInfo.tradeId)
              }
              this.tradeId = res.data.data.getOrderInfo.tradeId
              this.userInfo = res.data.data.getOrderInfo; //总信息信息
              this.shopsGoods = res.data.data.listOrderItems; //渲染订单列表
              this.changeIsShow = res.data.data.listOrderItems.map((v) => {
                return v[0].status;
              });
              // Toast(`${res.data.data.getOrderInfo.remark}`)
              this.appraisesStatus = res.data.data.getOrderInfo.appraisesStatus; //是否已评价
              this.expressInfo = res.data.data.expressDetails;
              if (res.data.data.getOrderInfo.status != "1") {
                getOrderLogistics().then((res1) => {
                  this.logisticsName = res1.data.data.filter(
                    (v) => v.value === this.expressInfo.com
                  )[0].label;
                });
              }
            } else if (res.data.data.MergeOrderInfo) {
              this.userInfo = res.data.data.MergeOrderInfo; //总信息
              //未付款则显示付款倒计时
              this.willPayShopsGoods = res.data.data.listOrderItems; //渲染订单列表
              this.ids = [res.data.data.MergeOrderInfo.mergeOrderId];
              this.getRemainTime(res.data.data.MergeOrderInfo.createTime); //待付款倒计时
            }
            if (res.data.data.orderItemDTO) {//优惠券
              this.goodsCoupon = res.data.data.orderItemDTO.conpouDTO
            }
          }
        })
        .catch((error) => {
          Toast.fail("加载失败,请退出重试", error);
        });

    },
    //修改地址（待发货）
    editAddress() {
      this.$router.push({
        path: "/editAddress",
        query: {
          id: this.$route.query.id,
          shopType: '0'//红尘坊
        },
      });
    },
    //打电话方法：
    callPhone() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        // window.location.href = 'tel:15365526315'
        this.$refs.tels.href = "tel://15365526315";
        this.$refs.tels.click();
      }
      if (isAndroid) {
        window.android.callPhone("15365526315");
      }
    },
    getCopy(dom) {
      // 1. 绑定剪贴板操作方法(订单号)
      let clipboard = new ClipboardJS(dom, {
        text: function (trigger) {
          // 2. trigger 就是点击的 dom 元素，所以就可以通过它来获取到它的属性 'dataClipboard' 的值了
          // 3. 从这个方法返回的 string 就是会复制到剪贴板中的内容，所以可以复制任何内容了，也可以在这里处理一些逻辑
          // 4. 我在这里就只是单纯的输出了事先绑定好的值
          // console.log(trigger.getAttribute('dataClipboard'));
          return trigger.getAttribute("dataClipboard");
        },
      });
      // 5. 当复制成功的时候提示复制成功
      clipboard.on("success", () => {
        // 还可以添加监听事件，如：复制成功后提示
        Toast.success("复制成功");
      });
    },
    //接收支付子传父
    getShow(val) {
      this.showPay = val;
      // this.paySuccess();
    },
    //金叶子支付成功
    redMiPay(val) {
      this.showPay = val;
      this.paySuccess();
    },
    //未付款关闭弹窗
    notPay(val) {
      this.showPay = val;
      // this.paySuccess();
    },
    //支付成功调用方法
    paySuccess() {
      this.isPaySuccess = true;
      this.showInterstitialAD()
    },
    //待付款倒计时，这里需要判断订单超时怎么办
    getRemainTime(remainTime) {
      this.timer = setInterval(() => {
        let createOrder =
          new Date(remainTime.replace(/-/g, "/")).getTime() +
          1800000 -
          new Date().getTime();
        // this.shijian = createOrder
        let hours = Math.floor((createOrder / 1000 / 60 / 60) % 24);
        let minutes = Math.floor((createOrder / 1000 / 60) % 60);
        let second = Math.floor((createOrder / 1000) % 60);
        // console.log(hours, minutes);
        this.closeTime.hours = hours > 0 ? hours : "00";
        this.closeTime.minutes = minutes > 0 ? minutes : "00";
        this.closeTime.second = second > 0 ? second : "00";
      }, 500);
    },
    //点击跳转到店铺详情页
    toShopDetail(shopId) {
      // console.log(shopId, '店铺id');
      this.$router.push({
        path: "/flMallStoreDetail",
        query: {
          id: shopId,
          type: "h5",
        },
      });
    },
    //点击弹出取消订单选项
    cancelOrder() {
      this.cancelOrderShow = true;
    },
    //点击立即付款
    payNow() {
      this.showPay = true;
    },
    //点击暂不取消
    closePage() {
      this.cancelOrderShow = false;
    },
    //点击确认取消
    closeOrder() {
      //这里要发一个请求
      let params = { mergeId: this.userInfo.id, reason: this.radio };
      // console.log(params);
      sendCancelOrder(params)
        .then((res1) => {
          // Toast(res1)
          if (res1.status === 200) {
            Toast.success("取消成功");
            this.timer5 = setTimeout(() => {
              this.orderCancel();
            }, 500);
          }
        })
        .catch((err) => {
          Toast.fail("请求失败", err);
        });
      this.cancelOrderShow = false;
    },
    //点击申请退款
    hopeRefund(data) {
      if (this.tradeId) {
        this.refoundInfo1688.orderId = data.orderId;
        this.refoundInfo1688.itemId = data.itemId;
        this.show1688 = true;
      } else {
        this.refoundInfo.orderId = data.orderId;
        this.refoundInfo.itemId = data.itemId;
        this.returnMoneyShow = true;
      }
    },
    //点击确认退款原因
    submitRefound() {
      if (this.isDisabled) {
        this.isDisabled = false
        applyForRefund(this.refoundInfo)
          .then((res) => {
            // console.log(res);
            if (res.data.code === 0) {
              Toast.success("已申请");
              this.timer4 = setTimeout(() => {
                this.backToList();
              }, 500);
            } else {
              Toast.fail(`${res.data.message}`);
              this.isDisabled = true
              this.timer3 = setTimeout(() => {
                this.onClickLeft();
              }, 500);
            }
          })
          .catch((err) => {
            console.log(err, "err");
            this.isDisabled = true
            Toast(`退款失败${err}`);
            this.backToList();
          });
      }
    },
    //点击申请换货（待收货）
    changeNewGoods(data) {
      // this.isShowWin = !this.isShowWin;
      this.$router.push({
        path: "/selectService",
        query: { itemId: data.itemId, status: '2', shopType: '0' },
      });
    },
    //点击待收货的确认换货时
    asConfirmGet() {
      this.confirmGet();
    },
    //点击申请换货（已完成）
    hopeChange(data) {
      //这里需要传订单信息到换货页面
      /*  Toast.loading({ duration: 1 });
       this.$router.push({
         path: "/applyForChange",
         query: { data },
       }); */
      //shopType区分商城 0红尘坊，3侠客集市
      this.$router.push({
        path: "/selectService",
        query: { itemId: data.itemId, status: '3', shopType: '0' },
      });
    },
    //点击确认收货
    confirmGet() {
      //发请求
      confirmGetGoods({ orderId: this.userInfo.id })
        .then((res) => {
          Toast.success("确认成功");
          this.timer2 = setTimeout(() => {
            this.$router.push({
              path: "/confirmReceipt",
              query: { data: res.data.data },
            });
          }, 500);
        })
        .catch((err) => {
          Toast.fail("请刷新重试", err);
        });
    },
    //点击查询物流
    async searchLogistics() {
      //这里发请求查询物流
      if (this.expressInfo) {
        this.isShowLogistics = true;
      } else {
        // this.show = false
        Toast("暂无物流信息");
      }
    },
    //联系商家
    contactShop() {
      if (this.willPayShopsGoods.length != 0) {
        contact(this.willPayShopsGoods[0][0].accid);
      } else {
        contact(this.shopsGoods[0][0].accid);
      }
      //线下会报错，上线就没事了
      // contact(this.shopsGoods[0][0].accid)
    },
    //去评价
    goEvaluate() {
      Toast.loading({ duration: 1 });
      this.$router.push({
        path: "/goodsEvaluate",
        query: { data: this.shopsGoods },
      });
    },
    //回退
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    //退款成功回商品列表
    backToList() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.flMallOrderRefund.postMessage("");
      }
      if (isAndroid) {
        window.android.flMallOrderRefund();
      }
    },
    orderCancel() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.flMallOrderCancel.postMessage("");
      }
      if (isAndroid) {
        window.android.flMallOrderCancel();
      }
    },

    get1688reason (orderId) {
      get1688reason({ orderId }).then(res => {
        if (res.data.code === 0) {
          this.actions3 = res.data.data
        }
      })
    },

    change1688 (id) {
      const itme = this.actions3.find(x => x.id == id)
      this.refoundInfo1688.refundReson = itme.name
    },

    submitRefound1688 () {
      if (!this.refoundInfo1688.refundReasonId) {
        Toast('请选择退款原因')
        return
      }
      if (this.dou) {
        this.dou = false
        applyForRefund(this.refoundInfo1688)
          .then((res) => {
            if (res.data.code === 0) {
              Toast.success("已申请");
              this.timer4 = setTimeout(() => {
                this.backToList()
              }, 500)
            } else {
              Toast.fail(`${res.data.message}`)
              this.dou = true
              this.timer3 = setTimeout(() => {
                this.onClickLeft()
              }, 500)
            }
          })
          .catch((err) => {
            this.dou = true
            Toast(`退款失败${err}`)
            this.backToList();
          })
      }
    }
  },
  /* computed: {
      //对地址后面的null字符串进行处理
      fullAddress() {
        if (this.userInfo.address.includes("null")) {
          let newAddress = this.userInfo.address.replace("null", "");
          return newAddress;
        } else {
          return this.userInfo.address;
        }
      },
    }, */
  beforeDestroy() {
    this.clipboard.destroy();
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.timer2);
    this.timer2 = null;
    clearInterval(this.timer3);
    this.timer3 = null;
    clearInterval(this.timer4);
    this.timer4 = null;
    clearInterval(this.timer5);
    this.timer5 = null;
  },
};
</script>

<style lang="less" scoped>
.main {
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  section {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
  }

  .toLongHide {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 5rem;
    text-align: right;
  }

  .massage {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
  }

  .fontColor {
    line-height: 0.7784rem;
    color: #666666;
    font-size: 0.3459rem;
  }

  .top {
    height: 6%;
    font-size: 1.2973rem;
  }

  .willPay {
    display: flex;
    // height: 5%;
    padding: 0.4324rem 0.4324rem 0.6rem 0.4324rem;

    // margin: 0 0.4324rem;
    border-bottom: 1px solid #E5E5E5;

    img {
      height: 0.5405rem;
      width: 0.5946rem;
      margin-right: 0.2703rem;
    }
  }

  .userInfo {
    display: flex;
    // height: 6%;
    padding: 0.4324rem 0.4324rem 0.3rem 0.4324rem;

    .userInfo-one {
      flex: 1;

      .userInfo-one-info {

        display: flex;
        justify-content: space-between;
        align-items: start;

        button {
          border: 1px solid #999999;
          background: #fff;
          border-radius: 0.4324rem;
        }

        .userText {
          margin-right: 0.4324rem;
        }
      }
    }

    p {
      min-width: 6.9189rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      height: 0.5405rem;
      width: 0.5405rem;
      margin-right: 0.2703rem;
    }
  }

  .shopInfo {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 0.5135rem;

    .discounts {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      .discount {
        color: #E54320;
        font-size: 0.4324rem;
      }
    }

    .shopName {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      padding: 0.4324rem 0;

      span {
        margin: 0 0.4324rem;
        font-size: 0.35rem;
        max-width: 6.4865rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .goodsInfo {
      display: flex;
      flex-flow: column nowrap;
      padding: 0.4324rem 0;
      border-bottom: 1px solid rgba(170, 170, 170, .4);

      .goodsList {
        display: flex;
        flex-flow: row nowrap;

        .goodsText {
          margin-left: 0.5135rem;
          display: flex;
          flex: 1;
          flex-flow: column nowrap;
          justify-content: space-between;

          p {
            width: 6.4865rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .price {
            color: #E54320;
            font-size: 0.4324rem;
          }

          .size {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .returnGoods {
        padding: 0.4324rem 0 0 0;
        display: flex;
        justify-content: flex-end;

        button {
          max-width: 2.8rem;
          min-width: 2.4rem;
          height: 0.8649rem;
          // margin-right: 0.6919rem;
          border: 1px solid rgb(202, 202, 202);
          background: #fff;
          border-radius: 0.4324rem;
        }

        .buy {
          background: #E54320;
          color: #fff;
          border: none;
        }
      }

      .otherText {
        padding-top: 0.4324rem;

        .integrate {
          display: flex;
          justify-content: space-between;
        }

        .freight {
          display: flex;
          justify-content: space-between;
        }



        .remark {
          display: flex;
          justify-content: space-between;
        }
      }
    }

  }

  .totalInfo {
    line-height: 0.7784rem;
    padding: 0.2rem 0.5135rem;
    // border-bottom: 1px solid #E5E5E5;

    .total {
      display: flex;
      justify-content: space-between;
    }
  }

  .orderInfo {
    padding: 0.4324rem 0.5135rem;
    line-height: 0.7568rem;

    .orderNum {
      display: flex;
      justify-content: space-between;

      .copy {
        color: #E54320;
        margin-left: 0.2595rem;
        text-decoration: underline;
      }
    }
  }

  .serve {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0.4324rem 0;

    .serveBtn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 0.173rem;
      width: 2.6811rem;
      height: 0.8649rem;
      border: 1px solid rgb(202, 202, 202);
      background: #fff;
      border-radius: 0.4324rem;

      .btnA {
        color: #000;
        text-decoration: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        // -webkit-user-select: none;
        // -moz-user-focus: none;
        // -moz-user-select: none;
      }

      .btnImg {
        height: 0.5135rem;
        width: 0.5135rem // z-index: 1000;
      }
    }
  }

  .payment {
    .van-tabbar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // height: 1.6rem;

      button {
        width: 2.5946rem;
        height: 0.8649rem;
        margin-right: 0.6919rem;
        border: 1px solid #999999;
        background: #fff;
        border-radius: 0.4324rem;
      }

      .buy {
        background: #E54320;
        color: #fff;
        border: none;
      }

      .evaluate {
        border: none;
        background: #E54320;
        color: #fff;
      }

    }

  }

  .cancelOrderDetail {
    .van-action-sheet {
      .confirmPay {
        padding: 0.173rem 0.4324rem;
      }

      .logisticsTop {
        height: 2rem;
        width: 100%;
        box-sizing: border-box;
        padding: 0 1rem 0 0.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          img {
            vertical-align: middle;
          }
        }

        .right {
          span {
            padding: 0 0.2rem;
          }

          .leftSpan {
            // border-right: 1px solid #4A4A4A;
            color: #E54320;
            margin-left: 0.1rem;
            text-decoration: underline;
          }
        }
      }

      .van-steps {
        .logisticsText {
          h5 {
            font-weight: 300;
            line-height: 0.4324rem;
          }

          span {
            line-height: 0.6054rem;
          }
        }
      }

      .button {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 1.7297rem;

        button {
          width: 2.5946rem;
          height: 0.8649rem;
          margin-right: 0.6919rem;
          border: 1px solid #999999;
          background: #fff;
          border-radius: 0.4324rem;
        }

        .buy {
          background: #E54320;
          color: #fff;
        }
      }

      p {
        margin: 0.2rem 0;
        text-align: center;
        color: #999999;
      }

      .payPrice {
        text-align: center;
        color: #E54320;
        font-size: 0.7568rem;
      }

      .van-radio-group {
        padding: 0.4324rem 0.4324rem;

        .van-cell__title {
          margin: auto 0;
        }

        .van-radio {
          height: 1.7297rem;
        }
      }
    }
  }

  .footer {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    /* p {
			text-align: center;
			color: #999999;
		} */
  }
}
</style>
