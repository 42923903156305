<template>
  <div>
    <van-action-sheet v-model="_show" close-on-click-overlay closeable @click-overlay="notPay">
      <div class="content">
        <div style="text-align: center">
          <span style="font-size: 16px; color: #f52556">￥</span>
          <span style="font-size: 24px; color: #f52556">{{ userInfo.sum }}&ensp;</span>
          <span v-show="userInfo.needRice && userInfo.needRice > 0" style="font-size: 24px; color: #f52556">+&ensp;{{
            userInfo.needRice }}</span>
          <span v-show="userInfo.needRice && userInfo.needRice > 0" style="font-size: 14px; color: #f52556"> 驿马</span>
        </div>

        <div class="paymentType">
          <div class="paymentType-item" v-show="userInfo.needRice && userInfo.needRice > 0">
            <span class="paymentType-item-type">个人驿马:&ensp;{{ totalRiceGrains }}</span>
            <span v-show="Number(totalRiceGrains) < Number(userInfo.needRice)" style="color:#E54320;">
              余额不足
            </span>
            <van-icon v-show="Number(totalRiceGrains) >= Number(userInfo.needRice)" name="checked" color="#E54320"
              size="18" />
          </div>
          <!-- <div class="paymentType-item" @click="paymentType(1)">
            <img style="width: 18px" src="../../assets/img/weixin.png" alt="" />
            <span class="paymentType-item-type">微信支付</span>
            <span>
              <van-icon v-show="type != 1" name="checked" color="#b7b7b7" size="18" />
              <van-icon v-show="type == 1" name="checked" color="#E54320" size="18" />
            </span>
          </div> -->
          <div class="paymentType-item border-btn" @click="paymentType(2)">
            <img style="width: 18px" src="../../assets/img/alipay-icon.png" alt="" />
            <span class="paymentType-item-type">支付宝支付</span>
            <span>
              <van-icon v-show="type != 2" name="checked" color="#b7b7b7" size="18" />
              <van-icon v-show="type == 2" name="checked" color="#E54320" size="18" />
            </span>
          </div>
        </div>

        <div class="footer">
          <van-button round color="#E54320" size="large" @click="payMoney">确认付款</van-button>
        </div>
      </div>
    </van-action-sheet>
    <!-- <loading :loading="loading" /> -->
  </div>
</template>

<script>
// import Loading from '@/views/flMall/actionSheet/Loading'
import { zhifu, getUserHorse } from "@/api/orderIdDetail/index.js";
import { fuli_yundian_pay } from "@/common";
import { Toast } from "vant";
export default {
  components: {
    // Loading
  },

  data() {
    return {
      type: 2, // 1 微信 2 支付宝 4 金叶子 0 2 3权益人 1 4发起人 5普通账户
      loading: false,
      joinId: "", //区分发起人和权益人
      timer: null,
      timer1: null,
      totalRiceGrains: '',
      one: null
    };
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },

  computed: {
    _show: {
      get() {
        return this.show;
      },
      set() {
        this.$parent.submitShow = false;
      },
    },
  },
  beforeMount() {
    //查询个人驿马，不足则不让支付
    getUserHorse().then((res) => {
      if (res.data.code == 0) {
        // this.totalRiceGrains = '1'
        this.totalRiceGrains = res.data.data.totalRiceGrains
      } else {
        Toast(res.data.message);
      }
      // console.log(res.data.data, "???");
    }).catch(() => {
      Toast.fail('个人驿马获取失败，请退出重试');
    })
  },
  methods: {
    //关闭弹出页
    closePay() {
      this.$emit("getSend", false);
    },
    redMiPay() {
      this.$emit("redMiPay", false);
    },
    //未付款关闭弹窗
    notPay() {
      this.$emit("notPay", false);
    },
    //微信，支付宝付款
    paymentType(type) {
      /*  if (
        this.payInfo.available < this.price &&
        type == 4 &&
        this.payInfo.welfare == 1
      ) {
        Toast.fail("金叶子不足，无法支付");
        return;
      } else if (
        this.payInfo.available > this.price &&
        this.payInfo.welfare == 1
      ) {
        this.type = type;
      } else { */
      this.type = type;
      // }
    },
    /* paymentTypeOne(val) {
      // console.log(val, '<===');
      if (val.v.available < this.price && this.payInfo.welfare == 1) {
        Toast.fail("账户余额不足");
        return;
      } else if (val.v.available > this.price && this.payInfo.welfare == 1) {
        this.one = val.i
        this.type = 4;
        this.joinId = val.v.id;
      } else {
        this.type = 4;
        this.joinId = val.v.id;
      }
    }, */
    //金叶子付款
    /* paymentType1(data) {
      if (data.money < this.price && this.payInfo.welfare == 1) {
        Toast.fail("账户余额不足");
        return;
      } else if (data.money > this.price && this.payInfo.welfare == 1) {
        this.one = 1
        this.type = 4;
        this.joinId = data.id;
      } else {
        this.type = 4;
        this.joinId = data.id;
      }
    },
    paymentType2(data) {
      if (data.money < this.price && this.payInfo.welfare == 1) {
        Toast.fail("账户余额不足");
        return;
      } else if (data.money > this.price && this.payInfo.welfare == 1) {
        this.one = 2
        this.type = 4;
        this.joinId = data.id;
      } else {
        this.type = 4;
        this.joinId = data.id;
      }
    },
    paymentType3(data) {
      if (data.money < this.price && this.payInfo.welfare == 1) {
        Toast.fail("账户余额不足");
        return;
      } else if (data.money > this.price && this.payInfo.welfare == 1) {
        this.one = 3
        this.type = 4;
        this.joinId = data.id;
      } else {
        this.type = 4;
        this.joinId = data.id;
      }
    }, */
    //确认支付
    payMoney() {
      if (this.userInfo.needRice && this.userInfo.needRice > 0) {
        if (Number(this.totalRiceGrains) < Number(this.userInfo.needRice)) {
          Toast.fail('驿马不足，无法支付')
          return
        }
      }
      this.loading = true;
      const data = {
        ids: this.data,
        type: this.type,
        // joinId: this.joinId,
      };
      console.log(data);
      if (this.type == 1) {
        // 微信
        // openPay(res, "1");
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        let paySession = window.sessionStorage.getItem("third-session");
        if (isIOS) {
          // Toast('返回首页')
          window.webkit.messageHandlers.wechatPay.postMessage(`requesetType=13&type=1&ids=${JSON.stringify(data.ids)}&session=${paySession}`)
        }
        if (isAndroid) {
          window.android.wxpay(`requesetType=13&type=1&ids=${JSON.stringify(data.ids)}&session=${paySession}`);
        }
        this.timer = setTimeout(() => {
          this.closePay();
        }, 1500)
      } else {
        zhifu(data)
          .then((res) => {
            if (res.data.code === 0) {
              if (this.type == 2) {
                // 支付宝
                fuli_yundian_pay(res.data, "2");

                this.timer = setTimeout(() => {
                  this.closePay();
                }, 1500)
              } else if (this.type == 4) {
                Toast("支付成功");
                this.redMiPay();
              }
            }
          })
          .catch((err) => {
            Toast("支付请求失败", err);
          });
      }

    },
  },
  destroyed() {
    clearTimeout(this.timer)
    clearTimeout(this.timer1)
  }
};
</script>

<style lang="less" scoped>
.content {
  padding: 30px 15px 20px 15px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .paymentType {
    margin: 10px 0;

    &-item {
      display: flex;
      align-items: center;
      padding: 12px 0;

      &-type {
        flex: 1;
        font-size: 14px;
        color: #333;
        margin-left: 10px;
      }
    }

    .border-btn {
      border-top: 1px solid #f6f6f6;
      border-bottom: 1px solid #f6f6f6;
    }
  }
}

.footer {
  .van-button--round {
    height: 1.1rem;
  }
}
</style>
