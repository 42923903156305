import https from '@/router/https'
//获取订单id详情
export const getOrderIdDetail = (params) => {
	return https.fetchGet('/mallapp/orderinfo/FlOrderDetails', params);
}
//取消订单
export const sendCancelOrder = (params) => {
	return https.fetchPost(`/mallapp/orderinfo/updateMergeOrderInfo?mergeId=${params.mergeId}&reason=${params.reason}`)
}
//点击确认收货
export const confirmGetGoods = (params) => {
	return https.fetchGet('/mallapp/orderinfo/FlConfirmGoods', params)
}
//点击确认收货，售后专用
export const confirmGetGoodsFL = (params) => {
	return https.fetchGet('/mallapp/orderinfo/FlConfirmGoods', params)
}
//申请退款
export const applyForRefund = (data) => {
	return https.fetchPost('/mallapp/orderrefunds/applyForRefund', data)
}
//获取物流信息
export const getOrderLogistics = () => {
	return https.fetchGet('/mallapp/orderrefunds/dict?type=LOGISTICS');
}
//获取支付接口信息
export const getPayStatus = () => {
	return https.fetchGet('/mallapp/orderinfo/cloudAndWelfare');
}
// 红尘坊支付新汇付
// export const zhifu = (data) => {
// 	return https.fetchPost('/mallapp/orderinfo/AdaPayWelFareMallPay', data)
// }
export const zhifu = (data) => {
	return https.fetchPost('/mallapp/orderinfo/aliPayWelFareMallPay', data)
}
// 红尘坊支付新汇付
export const getUserHorse = () => {
	return https.fetchGet('/mallapp/riceuser/getPersonPostHorse')
}
// 红尘坊支付老
/* export const zhifu = (data) => {
	return https.fetchPost('/mallapp/orderinfo/unifiedOrderWelFareAll', data)
} */